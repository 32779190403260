import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout/Layout';

const success = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learning Curve | Success</title>
            </Helmet>
            <Layout>
                <div className="container">
                    <div style={{width: '50%', margin: 'auto', marginTop: '8rem', marginBottom: '8rem'}} className="card shadow p-4">
                        <h1 className="text-dark">Email Sent!</h1>
                        <p className="text-secondary">We will get back to you shortly!</p>
                        <Link to="/">Return to site</Link>
                    </div>
                </div>
            </Layout>
        </Fragment>
    )
}

export default success
